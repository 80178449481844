import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
//shared components
import Navbar from '../shared/layout/NavBar1.shared';
import Navbar2 from '../shared/layout/NavBar2.shared';
import Footer from '../shared/layout/Footer.shared';
import Busqueda from '../shared/components/Busqueda.shared';

//pages de Incio
import SliderImagenes from '../Inicio/pages/Carousel.inicio';
import Banner from '../Inicio/pages/Banner.incio';
import Mensaje from '../Inicio/pages/Mensajes.inicio';
import Conocenos from '../Inicio/pages/Conocenos.inicio';
import Tegnologia from '../Inicio/pages/Tegnologia.inicio';
import Sucursales from '../Inicio/pages/Sucursales.inicio';

//pages
import StopAge from '../StopAge/index.stopAge';
import Ailens from '../Ailens/index.ailens';
import OwlHd from '../OwlHd/index.owlHd';
import Progresivos from '../Progresivos/index.progresivos';
import BlueClarity from '../BlueClarity/index.blueClarity';
import Transitions from '../Transitions/index.transitions';
import SunRx from '../SunRx/index.sunrRx';

//ecomerce
import PaginaProducto from '../Ecomerce/index.ecomerce';
//construction
import Construccion from '../shared/components/Construccion.shared';


const InicioImg = ({ handleSearch, searchTerm, setSearchTerm, isNavbarVisible }) => (
  <div className="fixed w-full h-screen overflow-hidden">
    <img src="/Portada/Portada.webp" className="w-full h-full object-cover" alt="Opticentro background" />
    <img src="/Portada/ImgInicial/Logo.webp" className="w-30 h-24 absolute top-[35%] left-1/2 transform -translate-x-1/2 hover:scale-110 transition-all duration-500 z-20" alt="Opticentro logo" />
    {!isNavbarVisible && (
      <Busqueda handleSearch={handleSearch} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
    )}
  </div>
);

function InicioPagina() {
  const [showMenuTecnologia, setShowMenuTecnologia] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [scrollY, setScrollY] = useState(0);

  const contentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setScrollY(scrollPosition);

      const contentHeight = contentRef.current ? contentRef.current.offsetHeight : 0;
      const threshold = contentHeight * 0.10;

      setIsNavbarVisible(scrollPosition > threshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSearch = (event) => {
    event.preventDefault();
    console.log('Searching for:', searchTerm);
  };

  const handleNavItemClick = (id) => {
    setSelectedPage(id);
    setShowMenuTecnologia(false);
  };

  const handleImageClick = (id) => {
    setSelectedPage(id);
  };

  const resetPage = () => {
    setSelectedPage(null);
  };

  const handleTecnologiaClick = () => {
    setShowMenuTecnologia(prev => !prev);
  };
  

  const handleTechnologyClick = (technology) => {
    setSelectedPage(technology);
  };

  const renderContent = () => {
    switch (selectedPage) {
      case "StopAge":
        return <StopAge />;
      case "Ailens":
        return <Ailens />;
      case "OwlHd":
        return <OwlHd />;
      case "Progresivos":
        return <Progresivos />;
      case "BlueClarity":
        return <BlueClarity />;
      case "Transitions":
        return <Transitions />;
      case "SunRx":
        return <SunRx />;
      case "EnConstruccion":
        return <Construccion />;
      case "Producto":
        return <PaginaProducto />;
      default:
        return (
          <>
            <Banner />
            <SliderImagenes onImageClick={handleImageClick} />
            <Mensaje />
            <Conocenos />
            <Tegnologia onTechnologyClick={handleTechnologyClick} />
            <Sucursales />
          </>
        );
    }
  };

  return (
    <div className="relative">
      <InicioImg 
        handleSearch={handleSearch} 
        searchTerm={searchTerm} 
        setSearchTerm={setSearchTerm} 
        isNavbarVisible={isNavbarVisible}
      />
      
      <AnimatePresence>
        {isNavbarVisible && (
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 0.3 }}
            className="fixed top-0 left-0 w-full z-50 bg-white shadow-md"
          >
            <Navbar
              onClickTecnologia={handleTecnologiaClick}
              onHoverContacto={() => {}}
              onHomeClick={resetPage}
              searchComponent={
                <Busqueda 
                  handleSearch={handleSearch} 
                  searchTerm={searchTerm} 
                  setSearchTerm={setSearchTerm} 
                />
              }
            />
          </motion.div>
        )}
      </AnimatePresence>

      <Navbar2 
        showMenu={showMenuTecnologia} 
        onNavItemClick={handleNavItemClick}
      />

      <div 
        className="relative z-10"
        style={{
          transform: `translateY(${Math.max(0, window.innerHeight - scrollY)}px)`,
          transition: 'transform 0.1s ease-out'
        }}
        ref={contentRef}
      >
        <div className="bg-white min-h-screen">
          {renderContent()}
        </div>
        
        <Footer />
      </div>
    </div>
  );
}

export default InicioPagina;