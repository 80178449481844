import React, { useState } from 'react';

const PaginaProducto = () => {
  const [quantity, setQuantity] = useState({ left: 1, right: 1 });
  const [singlePrescription, setSinglePrescription] = useState(true);

  return (
    <div className="max-w-6xl mx-auto p-4">
      <div className="flex flex-wrap -mx-4">
        {/* Imagen del producto */}
        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-0">
          <img src='Carousel/WEB OPTI_Lentes 01.webp' alt="Lentes de contacto" className="w-full rounded-lg shadow-lg" />
        </div>

        {/* Formulario de compra */}
        <div className="w-full md:w-1/2 px-4">
          <div className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex justify-between mb-4">
              <button className="px-4 py-2 text-blue-600 font-semibold border border-blue-600 rounded">
                Comprar una vez
              </button>
              <button className="px-4 py-2 bg-blue-100 text-blue-600 font-semibold rounded">
                Suscríbite
              </button>
            </div>

            <div className="border border-dashed border-blue-300 p-3 rounded-lg mb-6">
              <p className="text-center">
                <span className="font-semibold">Suscríbete</span> y gana{' '}
                <span className="text-3xl font-bold text-blue-500">15%</span> de descuento en tus{' '}
                <span className="bg-blue-600 text-white px-2 py-1 rounded">siguientes pedidos</span>
              </p>
            </div>

            <h2 className="text-xl font-semibold mb-2">Ingresa tu receta de lentes de contacto</h2>
            <a href="#" className="text-blue-500 text-sm mb-4 block">¿Cómo leer mi receta?</a>

            <div className="flex justify-between mb-4">
              <button 
                className={`flex items-center text-sm ${singlePrescription ? 'text-blue-600 font-semibold' : 'text-gray-600'}`}
                onClick={() => setSinglePrescription(true)}
              >
                <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                ¿Misma receta en los dos ojos?
              </button>
              <button 
                className={`flex items-center text-sm ${!singlePrescription ? 'text-blue-600 font-semibold' : 'text-gray-600'}`}
                onClick={() => setSinglePrescription(false)}
              >
                <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                ¿Diferente receta para cada ojo?
              </button>
            </div>

            <div className={`grid ${singlePrescription ? 'grid-cols-1' : 'grid-cols-2'} gap-4 mb-6`}>
              {singlePrescription ? (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Poder (Esfera)</label>
                  <select className="w-full border border-gray-300 rounded-md shadow-sm p-2">
                    <option>Elegir</option>
                    <option>-0.25</option>
                    <option>-0.50</option>
                    <option>-0.75</option>
                    <option>-1.00</option>
                    <option>+0.25</option>
                    <option>+0.50</option>
                    <option>+0.75</option>
                    <option>+1.00</option>
                  </select>
                </div>
              ) : (
                <>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Ojo Izq.</label>
                    <select className="w-full border border-gray-300 rounded-md shadow-sm p-2">
                      <option>Elegir</option>
                      <option>-0.25</option>
                      <option>-0.50</option>
                      <option>-0.75</option>
                      <option>-1.00</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Ojo Der.</label>
                    <select className="w-full border border-gray-300 rounded-md shadow-sm p-2">
                      <option>Elegir</option>
                      <option>+0.25</option>
                      <option>+0.50</option>
                      <option>+0.75</option>
                      <option>+1.00</option>
                    </select>
                  </div>
                </>
              )}
            </div>

            <div className={`flex ${singlePrescription ? 'justify-center' : 'justify-between'} mb-6`}>
              {singlePrescription ? (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Cantidad</label>
                  <div className="flex items-center">
                    <button onClick={() => setQuantity({...quantity, left: Math.max(1, quantity.left - 1)})} className="px-3 py-1 border rounded-l bg-gray-100">-</button>
                    <input type="number" value={quantity.left} readOnly className="w-12 text-center border-t border-b py-1" />
                    <button onClick={() => setQuantity({...quantity, left: quantity.left + 1})} className="px-3 py-1 border rounded-r bg-gray-100">+</button>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Cantidad</label>
                    <div className="flex items-center">
                      <button onClick={() => setQuantity({...quantity, left: Math.max(1, quantity.left - 1)})} className="px-3 py-1 border rounded-l bg-gray-100">-</button>
                      <input type="number" value={quantity.left} readOnly className="w-12 text-center border-t border-b py-1" />
                      <button onClick={() => setQuantity({...quantity, left: quantity.left + 1})} className="px-3 py-1 border rounded-r bg-gray-100">+</button>
                    </div>
                    <span className="text-xs text-gray-500">Izquierdo</span>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Cantidad</label>
                    <div className="flex items-center">
                      <button onClick={() => setQuantity({...quantity, right: Math.max(1, quantity.right - 1)})} className="px-3 py-1 border rounded-l bg-gray-100">-</button>
                      <input type="number" value={quantity.right} readOnly className="w-12 text-center border-t border-b py-1" />
                      <button onClick={() => setQuantity({...quantity, right: quantity.right + 1})} className="px-3 py-1 border rounded-r bg-gray-100">+</button>
                    </div>
                    <span className="text-xs text-gray-500">Derecho</span>
                  </div>
                </>
              )}
            </div>

            <button className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
              AGREGAR A LA BOLSA
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaginaProducto;