import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
        imgSrc: '/Owl/Chica/chica.webp',
        x: { mobile: 98, tablet: 98, desktop: 98},
        y: { mobile: 11, tablet: 11, desktop:  11 }, 
        imgWidth: { mobile: 1000, tablet: 1000, desktop: 1000 },
        imgHeight: { mobile: 860, tablet: 860, desktop: 860 },
    }, 
    frase2: { 
        imgSrc: '/Owl/Chica/lente.webp',
        x: { mobile: 111, tablet: 111, desktop: 111 },
        y: { mobile: 16, tablet: 16, desktop:  16 }, 
        imgWidth: { mobile: 460, tablet: 460, desktop: 460 },
        imgHeight: { mobile: 280, tablet: 280, desktop:280 },
    },
    frase4: { 
        text: "TUS INICIALES",
        style: "font-now-bold text-white ",
        x: { mobile: 55, tablet: 55, desktop: 55}, 
        y: { mobile: 54, tablet: 54, desktop: 54 },
        fontSize: { mobile: 83, tablet: 83, desktop: 83}
    },
    frase5: { 
        text: "GRABADAS CON", 
        style: "font-now-bold text-white",
        x: { mobile: 55, tablet: 55, desktop: 55}, 
        y: { mobile: 64, tablet: 64, desktop:64 },
        fontSize: { mobile: 83, tablet: 83, desktop: 83}
    },
    frase6: { 
        text: "PUNTA DE DIAMANTES", 
        style: "font-now-bold text-white",
        x: { mobile: 55, tablet: 55, desktop: 55}, 
        y: { mobile: 74, tablet: 74, desktop:74},
        fontSize: { mobile: 83, tablet: 83, desktop: 83}
    },
};


function Ranita() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Owl/Chica/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Ranita;