import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        text: "La Luz UV solo", 
        style: "font-now-thin text-white",
        x: { mobile: 102, tablet: 102, desktop: 102 }, 
        y: { mobile: 25, tablet: 25, desktop: 25 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase2: { 
        text: "representan el", 
        style: "font-now-thin text-white",
        x: { mobile: 102, tablet: 102, desktop: 102 }, 
        y: { mobile: 36, tablet: 36, desktop: 36 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase3: { 
        text: "La Luz infrarroja", 
        style: "font-now-thin  text-white",
        x: { mobile: 102, tablet: 102, desktop: 102 }, 
        y: { mobile: 60, tablet: 60, desktop: 60 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase4: { 
        text: "es el", 
        style: "font-now-thin text-white",
        x: { mobile: 102, tablet: 102, desktop: 102 }, 
        y: { mobile: 71, tablet: 71, desktop: 71 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase5: { 
        text: "de toda la radiación total", 
        style: "font-now-thin text-white",
        x: { mobile: 102, tablet: 102, desktop: 102 }, 
        y: { mobile: 86, tablet: 86, desktop: 86 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase6: { 
        text: <strong>7%</strong>, 
        style: "font-now-thin text-white",
        x: { mobile: 59, tablet: 59, desktop: 59 }, 
        y: { mobile: 52, tablet: 52, desktop: 52 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase7: { 
        text: <strong>54%</strong>, 
        style: "font-now-thin text-white",
        x: { mobile: 68, tablet: 68, desktop: 68 }, 
        y: { mobile: 40, tablet: 40, desktop: 40 }, 
        fontSize: { mobile: 180, tablet: 180, desktop: 180 }
    },
    frase8: { 
        text: <strong>7%</strong>, 
        style: "font-now-thin text-white",
        x: { mobile: 130, tablet: 130, desktop: 130 }, 
        y: { mobile: 29, tablet: 29, desktop: 29 }, 
        fontSize: { mobile: 305, tablet: 305, desktop: 305 }
    },
    frase9: { 
        text: <strong>54%</strong>, 
        style: "font-now-thin text-white",
        x: { mobile: 120, tablet: 120, desktop: 120 }, 
        y: { mobile: 74, tablet: 74, desktop: 74 }, 
        fontSize: { mobile: 260, tablet: 260, desktop: 260 }
    }
};


function Chica2() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/StopAge/Sol/Sol.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Chica2;
