import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: {
        ytSrc: "https://www.youtube.com/embed/1DXjHafjYnU?autoplay=1&controls=0&mute=1&showinfo=0&rel=0", 
        x: { mobile: 75, tablet: 75, desktop: 75 }, 
        y: { mobile: 15, tablet:15, desktop: 15 },
        imgWidth: { mobile: 1020, tablet: 1020, desktop: 1020},  
        imgHeight: { mobile: 760, tablet: 760, desktop: 760 },
    }
};

function YouTubeVideo() {
  return (    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        isBackground={true}
      />
    </div>
  );
}

export default YouTubeVideo;
