import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        text: "LA LUZ INFRARROJA", 
        style: "font-now-thin text-white",
        x: { mobile: 113, tablet: 113, desktop: 113 }, 
        y: { mobile: 8, tablet: 8, desktop: 8}, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase2: { 
        text: <strong> NO ES DAÑINA </strong>, 
        style: "font-now text-white",
        x: { mobile: 124, tablet: 124, desktop: 124 }, 
        y: { mobile: 16, tablet: 16, desktop: 16 }, 
        fontSize: { mobile: 75, tablet: 75, desktop: 75 }
    },
    frase3: { 
        text: "Si se aplica en un determinado rango de frecuencias", 
        style: "font-now-thin text-white",
        x: { mobile: 52, tablet: 52, desktop: 52 }, 
        y: { mobile: 38, tablet: 38, desktop: 38 }, 
        fontSize: { mobile: 85, tablet: 85, desktop: 85 }
    },
    frase4: { 
        text: <strong>600 nm y 950 nm</strong>, 
        style: "font-now-thin text-white",
        x: { mobile: 55, tablet: 55, desktop: 55 }, 
        y: { mobile: 52, tablet: 52, desktop: 52 }, 
        fontSize: { mobile: 256, tablet: 256, desktop: 256}
    },
    frase5: { 
        text: "la luz puede penetrar unos 5 milímetros en la piel sin", 
        style: "font-now-thin text-white",
        x: { mobile: 53, tablet: 53, desktop: 53 }, 
        y: { mobile: 69, tablet: 69, desktop: 69 }, 
        fontSize: { mobile: 85, tablet: 85, desktop: 85 }
    },
    frase6: { 
        text: "causar daño alguno.", 
        style: "font-now-thin text-white",
        x: { mobile: 81, tablet: 81, desktop: 81 }, 
        y: { mobile: 76, tablet: 76, desktop: 76 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase7: { 
        text: "Controlado en un determinado tiempo,", 
        style: "font-now-thin text-white",
        x: { mobile: 64, tablet: 64, desktop: 64 }, 
        y: { mobile: 84, tablet: 84, desktop: 84 }, 
        fontSize: { mobile: 85, tablet: 85, desktop: 85 }
    },
    frase8: { 
        text: "para evitar una larga exposición.", 
        style: "font-now-thin text-white",
        x: { mobile: 70, tablet: 70, desktop: 70 }, 
        y: { mobile: 92, tablet: 92, desktop: 92 }, 
        fontSize: { mobile: 85, tablet: 85, desktop: 85}
    }
};


function Gris() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/StopAge/Gris/FondoGris.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Gris;
