import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        imgSrc: '/AilensComponents/PerformanceNum/uno.webp',
        x: { mobile: 62, tablet: 62, desktop: 62 },
        y: { mobile: 17, tablet: 17, desktop: 17 }, 
        imgWidth: { mobile: 300, tablet: 300, desktop: 300 },
        imgHeight: { mobile: 300, tablet: 300, desktop: 300 },
    },
    frase2: { 
        imgSrc: '/AilensComponents/PerformanceNum/dos.webp',
        x: { mobile: 77, tablet: 77, desktop: 77 },
        y: { mobile: 17, tablet: 17, desktop: 17 }, 
        imgWidth: { mobile: 300, tablet: 300, desktop: 300 },
        imgHeight: { mobile: 300, tablet: 300, desktop: 300 },
    },
    frase3: { 
        imgSrc: '/AilensComponents/PerformanceNum/tres.webp',
        x: { mobile: 92, tablet: 92, desktop: 92 },
        y: { mobile: 17, tablet: 17, desktop: 17 }, 
        imgWidth: { mobile: 300, tablet: 300, desktop: 300 },
        imgHeight: { mobile: 300, tablet: 300, desktop: 300 },
    },
    frase4: { 
        imgSrc: '/AilensComponents/PerformanceNum/cuatro.webp',
        x: { mobile: 107, tablet: 107, desktop: 107 },
        y: { mobile: 17, tablet: 17, desktop: 17 }, 
        imgWidth: { mobile: 300, tablet: 300, desktop: 300 },
        imgHeight: { mobile: 300, tablet: 300, desktop: 300 },
    },
    frase5: { 
        imgSrc: '/AilensComponents/PerformanceNum/cinco.webp',
        x: { mobile: 122, tablet: 122, desktop: 122 },
        y: { mobile: 17, tablet: 17, desktop: 17 }, 
        imgWidth: { mobile: 300, tablet: 300, desktop: 300 },
        imgHeight: { mobile: 300, tablet: 300, desktop: 300 },
    },
    frase6: { 
        imgSrc: '/AilensComponents/Lentes/IzquierdaLente.webp',
        x: { mobile: 90, tablet: 90, desktop: 88 },
        y: { mobile: 65, tablet: 65, desktop: 65 }, 
        imgWidth: { mobile: 340, tablet: 340, desktop: 440 },
        imgHeight: { mobile: 250, tablet: 250, desktop: 300 },
    },
    frase7: { 
        text:  "Adaptación inmediata", 
        style: "font-now-bold text-gray-800",
        x: { mobile: 62, tablet: 62, desktop: 62.5 }, 
        y: { mobile: 43, tablet: 43, desktop: 43 }, 
        fontSize: { mobile: 30, tablet: 30, desktop: 27 }
    },
    frase8: { 
        text:  "en un corto periodo de", 
        style: "font-now text-gray-600",
        x: { mobile: 62, tablet: 62, desktop: 62.5 }, 
        y: { mobile: 47, tablet: 47, desktop: 47 }, 
        fontSize: { mobile: 30, tablet: 30, desktop: 27 }
    },
    frase9: { 
        text:  "tiempo", 
        style: "font-now text-gray-600",
        x: { mobile: 67, tablet: 67, desktop: 67 }, 
        y: { mobile: 51, tablet: 51, desktop: 51 }, 
        fontSize: { mobile: 30, tablet: 30, desktop: 27 }
    },

    frase10: { 
        text:  "Sin efecto swimming", 
        style: "font-now-bold text-gray-800",
        x: { mobile: 77.5, tablet: 77.5, desktop: 77.5 }, 
        y: { mobile: 43, tablet: 43, desktop: 43 }, 
        fontSize: { mobile: 30, tablet: 30, desktop: 27 }
    },
    frase11: { 
        text:  "cuando se enfoca en", 
        style: "font-now text-gray-600",
        x: { mobile: 77.5, tablet: 77.5, desktop: 77.5 }, 
        y: { mobile: 47, tablet: 47, desktop: 47 }, 
        fontSize: { mobile: 30, tablet: 30, desktop: 27 }
    },
    frase12: { 
        text:  "la periferia  del lente", 
        style: "font-now text-gray-600",
        x: { mobile: 77.5, tablet: 77.5, desktop: 77.5 }, 
        y: { mobile: 51, tablet: 51, desktop: 51 }, 
        fontSize: { mobile: 30, tablet: 30, desktop: 27 }
    },
    frase13: { 
        text:  " Campos de visión", 
        style: "font-now-bold text-gray-800",
        x: { mobile: 93.5, tablet: 93.5, desktop: 93.5 }, 
        y: { mobile: 43, tablet: 43, desktop: 43 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase14: { 
        text:  " optimizados y adaptados", 
        style: "font-now text-gray-600",
        x: { mobile: 91.5, tablet: 91.5, desktop: 91.5 }, 
        y: { mobile: 47, tablet: 47, desktop: 47 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase15: { 
        text:  "al estilo de vida del cliente.", 
        style: "font-now text-gray-600",
        x: { mobile: 91.5, tablet: 91.5, desktop: 91.5 }, 
        y: { mobile: 51, tablet: 51, desktop: 51 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase16: { 
        text:  "Excelente percepción", 
        style: "font-now-bold text-gray-800",
        x: { mobile: 107.5, tablet: 107.5, desktop: 107.5 }, 
        y: { mobile: 43, tablet: 43, desktop: 43 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase17: { 
        text:  "espacial. También en las ", 
        style: "font-now text-gray-600",
        x: { mobile: 106.5 , tablet: 106.5 , desktop: 106.5 }, 
        y: { mobile: 47, tablet: 47, desktop: 47 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase18: { 
        text:  "zonas periféricas.", 
        style: "font-now text-gray-600",
        x: { mobile: 109.5, tablet:109.5, desktop: 109.5 }, 
        y: { mobile: 51, tablet: 51, desktop: 51 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase19: { 
        text:  " Visión natural e", 
        style: "font-now-bold text-gray-800",
        x: { mobile: 124.5, tablet: 124.5, desktop: 124.5 }, 
        y: { mobile: 43, tablet: 43, desktop: 43 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase20: { 
        text:  "todas las situaciones", 
        style: "font-now text-gray-600",
        x: { mobile: 123, tablet: 123, desktop: 123 }, 
        y: { mobile: 47, tablet: 47, desktop: 47 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase21: { 
        text:  " (día y noche).", 
        style: "font-now text-gray-600",
        x: { mobile: 125.5, tablet: 125.5, desktop: 125.5 }, 
        y: { mobile: 51, tablet: 51, desktop: 51 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
};

function PerformanceNum() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/AiLensComponents/PerformanceNum/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default PerformanceNum;