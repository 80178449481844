import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
        imgSrc: '/BlueClarity/Banner.webp',
        x: { mobile: 56.5, tablet: 56.5, desktop: 56.5},
        y: { mobile: 6, tablet: 6, desktop:  6 }, 
        imgWidth: { mobile: 200, tablet: 200, desktop: 200 },
        imgHeight: { mobile: 130, tablet: 130, desktop: 130 },
    }, 
    frase2: { 
        text: "LA LUZ AZUL",
        style: "font-now-bold text-white ",
        x: { mobile: 90, tablet: 90, desktop: 90}, 
        y: { mobile: 30, tablet: 30, desktop: 30 },
        fontSize: { mobile: 69, tablet: 69, desktop: 69}
    },
    frase3: { 
        text: "ES EMITIDA DE FORMA NATURAL A TRAVÉS DE",
        style: "font-now-thin text-white ",
        x: { mobile: 68, tablet: 68, desktop: 68}, 
        y: { mobile: 40, tablet: 40, desktop: 40 },
        fontSize: { mobile: 65, tablet: 65, desktop: 65}
    },
    frase4: { 
        text: "LOS RAYOS SOLARES Y ES NECESARIA PARA",
        style: "font-now-thin text-white ",
        x: { mobile: 69, tablet: 69, desktop: 69}, 
        y: { mobile: 50, tablet: 50, desktop: 50 },
        fontSize: { mobile: 65, tablet: 65, desktop: 65}
    },
    frase5: { 
        text: "NUESTRO ORGANISMO, ARTIFICIALMENTE ES",
        style: "font-now-thin text-white ",
        x: { mobile: 68, tablet: 68, desktop: 68}, 
        y: { mobile: 60, tablet: 60, desktop: 60 },
        fontSize: { mobile: 65, tablet: 65, desktop: 65}
    },
    frase6: { 
        text: "EMITIDA POR DISPOSITIVOS ELECTRÓNICOS.",
        style: "font-now-thin text-white ",
        x: { mobile: 68.5, tablet: 68.5, desktop: 68.5}, 
        y: { mobile: 70, tablet: 70, desktop: 70 },
        fontSize: { mobile: 65, tablet: 65, desktop: 65}
    },
};


function Gris() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/BlueClarity/Gris/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Gris;