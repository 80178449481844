import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared'; 

const frases = {
  frase7: { 
    text: "PROVOCANDO", 
    style: "font-now-bold text-white",
    x: { mobile: 76, tablet: 76, desktop: 76 }, 
    y: { mobile: 6.5, tablet: 6.5, desktop: 6.5}, 
    fontSize: { mobile: 140, tablet: 140, desktop: 140 }
  },
  frase8: { 
    text: "OJO SECO", 
    style: "font-now-thin text-white",
    x: { mobile: 65, tablet: 65, desktop: 65 }, 
    y: { mobile: 90, tablet: 90, desktop: 90 }, 
    fontSize: { mobile: 88, tablet: 88, desktop: 88 }
  },
  frase9: { 
    text: "CATARATAS", 
    style: "font-now-thin text-white",
    x: { mobile: 115, tablet: 115, desktop: 115 },
    y: { mobile: 90, tablet: 90, desktop: 90 }, 
    fontSize: { mobile: 88, tablet: 88, desktop: 88 }
  }
};

function Provocando() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/StopAge/Provocando/Provocando.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Provocando;