import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import useScreenSize from '../hooks/useScreenSize.shared';

const Navbar2 = forwardRef(({ showMenu, onNavItemClick }, ref) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { deviceType } = useScreenSize();
  const navRef = useRef(null);

  const navItems = [
    { id: "StopAge", icon: "/iconos/StopAgeIcon.webp", alt: "Stop Age" },
    { id: "Ailens", icon: "/iconos/AilensIcon.webp", alt: "AI Lens" },
    { id: "OwlHd", icon: "/iconos/OwlIcon.webp", alt: "Owl HD" },
    { id: "Progresivos", icon: "/iconos/ProgresivosIcon.webp", alt: "Progresivos" },
    { id: "BlueClarity", icon: "/iconos/BlueIcon.webp", alt: "Blue Clarity" },
    { id: "EnConstruccion", icon: "/iconos/TransitionsIcon.webp", alt: "Transitions" },
    { id: "EnConstruccion", icon: "/iconos/SunRxIcon.webp", alt: "Sun Rx" },
  ];

  useEffect(() => {
    setIsMenuOpen(showMenu);
  }, [showMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleInteraction = (id) => {
    if (onNavItemClick) {
      onNavItemClick(id);
    }
    if (deviceType !== 'desktop') {
      setIsMenuOpen(false);
    }
  };

  const getImageSize = () => {
    switch(deviceType) {
      case 'mobile':
      case 'tablet':
        return 'w-12 h-12';
      case 'desktop':
      default:
        return 'w-20 h-10';
    }
  };

  const getNavbarStyle = () => {
    const baseStyle = {
      backgroundColor: '#71757a',
      transition: 'all 0.3s ease-in-out',
      zIndex: 40,
    };

    switch(deviceType) {
      case 'mobile':
        return {
          ...baseStyle,
          position: 'fixed',
          top: '210px',
          left: isMenuOpen ? '0' : '-15%',
          width: '15%',
          height: 'calc(100% - 60px)',
        };
      case 'tablet':
        return {
          ...baseStyle,
          position: 'fixed',
          top: '69px',
          left: isMenuOpen ? '0' : '-15%',
          width: '15%',
          height: 'calc(100% - 60px)',
        };
      case 'desktop':
      default:
        return {
          ...baseStyle,
          position: 'fixed',
          top: '60px',
          left: 0,
          width: '100%',
          height: 'auto',
        };
    }
  };

  return (
    <nav className={`shadow-md ${isMenuOpen ? 'block' : 'hidden'}`} style={getNavbarStyle()} ref={navRef}>
      {(deviceType === 'mobile' || deviceType === 'tablet') && (
        <button onClick={toggleMenu} className="absolute top-2 right-2 text-white text-2xl p-2">
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      )}
      <ul className={`flex ${deviceType === 'desktop' ? 'flex-row justify-center' : 'flex-col items-center'} h-full overflow-y-auto ${deviceType === 'desktop' ? 'space-x-7' : 'space-y-3'} py-3`}>
        {navItems.map((item, index) => (
          <li key={index} className="flex-none">
            <button
              onClick={() => handleInteraction(item.id)}
              className="flex flex-col items-center hover:bg-[#8e9399] rounded transition duration-300 p-2"
            >
              <img src={item.icon} alt={item.alt} className={`${getImageSize()} object-contain`} />
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
});

export default Navbar2;