import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import './Sucursales.css';

function Sucursales() {
    const sucursales = [
        { name: "SUCRE" },
        { name: "TARIJA" },
        { name: "POTOSÍ" },
        { name: "LA PAZ" },
        { name: "YACUIBA" },
        { name: "TUPIZA" }
    ];

    return (
        <div className="container-todo">
            <div className="sucursales-container">
                {sucursales.map((sucursal, index) => (
                    <div key={index} className="sucursal-item">
                        <FaMapMarkerAlt className="sucursal-icon" />
                        <p className="sucursal-name">{sucursal.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Sucursales;