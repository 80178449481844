import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        text: "La luz infrarroja puede penetrar", 
        style: "font-now-thin text-white",
        x: { mobile: 98, tablet: 98, desktop: 98}, 
        y: { mobile: 10, tablet: 10, desktop: 10}, 
        fontSize: { mobile: 70, tablet: 70, desktop: 70 }
    },
    frase2: { 
        text:  "profundamente en los tejidos, generando",
        style: "font-now text-white",
        x: { mobile: 86, tablet: 86, desktop: 86 }, 
        y: { mobile: 19, tablet: 19, desktop: 19 }, 
        fontSize: { mobile: 70, tablet: 70, desktop: 70 }
    },
    frase3: { 
        text: "calor y provocando daños a nivel", 
        style: "font-now-thin text-white",
        x: { mobile: 95, tablet: 95, desktop: 95 }, 
        y: { mobile: 28, tablet: 28, desktop: 28 }, 
        fontSize: { mobile: 70, tablet: 70, desktop: 70 }
    },
    frase4: { 
        text: "celular,", 
        style: "font-now-thin text-white",
        x: { mobile: 84, tablet: 84, desktop: 84 }, 
        y: { mobile: 37, tablet: 37, desktop: 37 }, 
        fontSize: { mobile: 70, tablet: 70, desktop: 70 }
    },
    frase5: { 
        text:  <strong> acelerando el envejecimiento</strong>, 
        style: "font-now-thin text-white",
        x: { mobile: 98, tablet: 98, desktop: 98 }, 
        y: { mobile: 37, tablet: 37, desktop: 37 }, 
        fontSize: { mobile: 70, tablet: 70, desktop: 70 }
    },
    frase6: { 
        text: "y", 
        style: "font-now-thin text-white",
        x: { mobile: 144, tablet: 144, desktop: 144 }, 
        y: { mobile: 37, tablet: 37, desktop: 37 }, 
        fontSize: { mobile: 70, tablet: 70, desktop: 70 }
    },
    frase7: { 
        text: "deterioro de los tejidos.", 
        style: "font-now-thin text-white",
        x: { mobile: 110, tablet: 110, desktop: 110 }, 
        y: { mobile: 47, tablet: 47, desktop: 47 }, 
        fontSize: { mobile: 70, tablet: 70, desktop: 70 }
    },
    frase8: { 
        imgSrc: '/StopAge/Piel/Aire.webp', 
        x: { mobile: 123, tablet: 123, desktop: 123 }, 
        y: { mobile: 58, tablet: 58, desktop: 58 }, 
        imgWidth: { mobile: 160, tablet: 160, desktop: 160 },
        imgHeight: { mobile: 140, tablet: 140, desktop: 140 },
    },
    frase9: { 
        imgSrc: '/StopAge/Piel/Aire.webp', 
        x: { mobile: 70, tablet: 70, desktop: 70 }, 
        y: { mobile: 58, tablet: 58, desktop: 58 }, 
        imgWidth: { mobile: 160, tablet: 160, desktop: 160 },
        imgHeight: { mobile: 140, tablet: 140, desktop: 140 },
    },
};

function Piel() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/StopAge/Piel/Piel.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Piel;