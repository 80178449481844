import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        imgSrc: '/Owl/Ranita/rana.webp',
        x: { mobile: 91, tablet: 91, desktop: 91 },
        y: { mobile: 22, tablet: 22, desktop:  22 }, 
        imgWidth: { mobile: 680, tablet: 680, desktop: 680 },
        imgHeight: { mobile: 480, tablet: 480, desktop: 480 },
    },
    frase2: { 
        imgSrc: '/Owl/Ranita/owlHdIcono.webp',
        x: { mobile: 135, tablet: 135, desktop: 135 },
        y: { mobile: 80, tablet: 80, desktop:  80 }, 
        imgWidth: { mobile: 200, tablet: 200, desktop: 200 },
        imgHeight: { mobile: 120, tablet: 120, desktop: 120 },
    }, 
    frase3: { 
        imgSrc: '/Owl/Ranita/photo.webp',
        x: { mobile: 54, tablet: 54, desktop: 54 },
        y: { mobile: 14, tablet: 14, desktop:  14 }, 
        imgWidth: { mobile: 280, tablet: 280, desktop: 280 },
        imgHeight: { mobile: 280, tablet: 280, desktop: 280 },
    }, 
    
    frase4: { 
        text: "MEJOR",
        style: "font-now-bold text-white ",
        x: { mobile: 55, tablet: 55, desktop: 55}, 
        y: { mobile: 54, tablet: 54, desktop: 54 },
        fontSize: { mobile: 93, tablet: 93, desktop: 93}
    },
    frase5: { 
        text: "PERCEPCIÓN", 
        style: "font-now-bold text-white",
        x: { mobile: 55, tablet: 55, desktop: 55}, 
        y: { mobile: 64, tablet: 64, desktop:64 },
        fontSize: { mobile: 93, tablet: 93, desktop: 93}
    },
    frase6: { 
        text: "DE LOS COLORES", 
        style: "font-now-bold text-white",
        x: { mobile: 55, tablet: 55, desktop: 55}, 
        y: { mobile: 74, tablet: 74, desktop:74},
        fontSize: { mobile: 93, tablet: 93, desktop: 93}
    },
};


function Ranita() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Owl/Ranita/ranita.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Ranita;