import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
        imgSrc: '/BlueClarity/Banner.webp',
        x: { mobile: 56.5, tablet: 56.5, desktop: 56.5},
        y: { mobile: 6, tablet: 6, desktop:  6 }, 
        imgWidth: { mobile: 200, tablet: 200, desktop: 200 },
        imgHeight: { mobile: 130, tablet: 130, desktop: 130 },
    },
    frase2: { 
      imgSrc: '/BlueClarity/Azul/un.webp',
      x: { mobile: 73, tablet: 73, desktop: 73},
      y: { mobile: 23, tablet: 23, desktop:  23 }, 
      imgWidth: { mobile: 250, tablet: 250, desktop: 250 },
      imgHeight: { mobile: 150, tablet: 150, desktop: 150 },
    },
    frase3: { 
      imgSrc: '/BlueClarity/Azul/do.webp',
      x: { mobile:  89.5, tablet:  89.5, desktop: 89.5},
      y: { mobile: 23, tablet: 23, desktop:  23 }, 
      imgWidth: { mobile: 105, tablet: 105, desktop: 105 },
      imgHeight: { mobile: 150, tablet: 150, desktop: 150 },
    },
    frase4: { 
      imgSrc: '/BlueClarity/Azul/tr.webp',
      x: { mobile: 100.5, tablet: 100.5, desktop: 100.5},
      y: { mobile: 23, tablet: 23, desktop: 23 }, 
      imgWidth: { mobile: 105, tablet: 105, desktop: 105 },
      imgHeight: { mobile: 150, tablet: 150, desktop: 150 },
    },
    frase5: { 
      imgSrc: '/BlueClarity/Azul/cu.webp',
      x: { mobile: 111.5, tablet: 111.5, desktop: 111.5},
      y: { mobile: 23, tablet: 23, desktop: 23 }, 
      imgWidth: { mobile: 140, tablet: 140, desktop: 140 },
      imgHeight: { mobile: 140, tablet: 140, desktop: 140 },
    },
    frase6: { 
      imgSrc: '/BlueClarity/Azul/ci.webp',
      x: { mobile: 127, tablet: 127, desktop: 127},
      y: { mobile: 23, tablet: 23, desktop:  23}, 
      imgWidth: { mobile: 140, tablet: 140, desktop: 140 },
      imgHeight: { mobile: 140, tablet: 140, desktop: 140 },
    },
    frase7:{
        text: "¿DÓNDE LA ENCONTRAMOS?", 
        style: "font-now-bold text-white",
        x: { mobile: 72, tablet: 72, desktop: 72}, 
        y: { mobile: 10, tablet: 10, desktop:  10}, 
        fontSize: { mobile: 90, tablet: 90, desktop: 90}
    },
};


function Azul() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/BlueClarity/Azul/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Azul;