import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import useScreenSize from '../hooks/useScreenSize.shared';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1, 
    transition: { 
      duration: 0.5, 
      when: "beforeChildren", 
      staggerChildren: 0.1 
    } 
  }
};

const textVariants = {
  hidden: { y: 20 },
  visible: { y: 0, transition: { duration: 0.5, ease: "easeOut" } }
};

const imageVariants = {
  hidden: { scale: 0.9 },
  visible: { scale: 1, transition: { duration: 0.5, ease: "easeOut" } }
};

const iframeVariants = {
  hidden: { x: -20 },
  visible: { x: 0, transition: { duration: 0.5, ease: "easeOut" } }
};

const buttonVariants = {
  hidden: { scale: 0.8 },
  visible: { scale: 1, transition: { duration: 0.3, ease: "easeOut" } }
};

const typingAnimation = {
  hidden: { width: '0%' },
  visible: { width: '100%', transition: { duration: 1, ease: "easeOut" } }
};

function AnimatedSection({ frases, imageSrc, isBackground, onButtonClick, defaultDimensions = { width: 1920, height: 1080 } }) {
  const screenSize = useScreenSize();
  const controls = useAnimation();
  const [ref, inView] = useInView({ 
    triggerOnce: false, 
    threshold: 0.1,
    rootMargin: '-10% 0px -10% 0px'
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [inView, controls]);

  const scaleFactor = Math.min(
    screenSize.width / defaultDimensions.width,
    screenSize.height / defaultDimensions.height
  );

  const getResponsiveValue = (value) => {
    if (typeof value === 'object') {
      return value[screenSize.deviceType] || value.desktop;
    }
    return value;
  };

  const getScaledValue = (value) => {
    const responsiveValue = getResponsiveValue(value);
    return typeof responsiveValue === 'number' ? responsiveValue * scaleFactor : responsiveValue;
  };

  const getResponsiveClasses = (styles) => {
    if (!styles) return '';
    return getResponsiveValue(styles) || '';
  };

  const getPositionStyle = (x, y, fontSize) => {
    const xPos = getResponsiveValue(x);
    const yPos = getResponsiveValue(y);
    const size = getScaledValue(fontSize);

    return {
      left: `${xPos}%`,
      top: `${yPos}%`,
      fontSize: typeof size === 'number' ? `${size}px` : size,
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
    };
  };

  const getImageStyle = (imgWidth, imgHeight) => {
    const width = getScaledValue(imgWidth);
    const height = getScaledValue(imgHeight);

    return {
      width: `${width}px`,
      height: `${height}px`,
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
    };
  };

  const getIframeStyle = (imgWidth, imgHeight) => {
    const width = getScaledValue(imgWidth);
    const height = getScaledValue(imgHeight);

    return {
      width: `${width}px`,
      height: `${height}px`,
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
    };
  };

  const getButtonStyle = (x, y, btnWidth, btnHeight, fontSize) => {
    const xPos = getResponsiveValue(x);
    const yPos = getResponsiveValue(y);
    const size = getScaledValue(fontSize);
    const buttonWidth = getScaledValue(btnWidth);
    const buttonHeight = getScaledValue(btnHeight);

    return {
      left: `${xPos}%`,
      top: `${yPos}%`,
      width: `${buttonWidth}px`,
      height: `${buttonHeight}px`,
      fontSize: typeof size === 'number' ? `${size}px` : size,
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
    };
  };

  return (
    <motion.div 
      ref={ref}
      className="relative w-full h-0 overflow-hidden"
      style={{ paddingBottom: '50%' }}
      variants={containerVariants}
      initial="hidden"
      animate={controls}
    >
      <div 
        className="absolute top-0 left-0 w-full h-full bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${imageSrc})` }}
      >
        {isBackground && (
          <motion.div 
            className="flex items-center justify-center absolute inset-0 w-full h-full object-cover"
            style={{ overflow: "hidden" }}
            variants={imageVariants}
          />
        )}

        
        {Object.entries(frases).map(([key, { 
          text, 
          style, 
          x, 
          y, 
          fontSize, 
          imgSrc, 
          imgWidth, 
          imgHeight, 
          ytSrc, 
          textbutton, 
          btnWidth, 
          btnHeight,
          opacity = 0,
          customStyles
        }]) => (
          <motion.div
            key={key}
            style={getPositionStyle(x, y, fontSize)}
            className={`absolute whitespace-nowrap opacity-${opacity} ${style || ''} ${getResponsiveClasses(customStyles)}`}
            variants={typingAnimation}
          >
            {text && (
              <motion.span
                variants={textVariants}
                className={getResponsiveClasses(customStyles?.text)}
              >
                {text}
              </motion.span>
            )}
            {imgSrc && (
              <motion.img 
                src={imgSrc}
                alt={key} 
                style={getImageStyle(imgWidth, imgHeight)}
                className={getResponsiveClasses(customStyles?.image)}
                variants={imageVariants}
              />
            )}
            {ytSrc && (
              <motion.iframe
                src={ytSrc}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={getIframeStyle(imgWidth, imgHeight)}
                className={getResponsiveClasses(customStyles?.iframe)}
                variants={iframeVariants}
              ></motion.iframe>
            )}
            {textbutton && (
              <motion.button
                style={getButtonStyle(x, y, btnWidth, btnHeight, fontSize)}
                className={`bg-[#71757a] text-white rounded-md hover:bg-[#a4a5a9] transition-colors duration-300 ${getResponsiveClasses(customStyles?.button)}`}
                onClick={onButtonClick}
                variants={buttonVariants}
              >
                {textbutton}
              </motion.button>
            )}
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
}

export default AnimatedSection;