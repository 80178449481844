import React from 'react';

function SunRx() {
  return (
    <div>
      <h1>SUN RX</h1>
    </div>
  );
} 

export default SunRx;