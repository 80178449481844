import React, { useState, useEffect, useRef } from 'react';

const images = [
  { src: 'Carousel/WEB OPTI_Lentes 01.webp', id: 'Producto' },
  { src: 'Carousel/WEB OPTI_Lentes 02.webp', id: 'Producto' },
  { src: 'Carousel/WEB OPTI_Lentes 03.webp', id: 'Producto' },
  { src: 'Carousel/WEB OPTI_Lentes 01.webp', id: 'Producto' },
];

function SliderImagenes({ onImageClick }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleImageClick = (id) => {
    onImageClick(id);
  };

  const renderImages = () => {
    const displayImages = [...images, ...images]; 

    return displayImages.map((image, index) => (
      <div
        key={index}
        className="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 h-full cursor-pointer" 
        onClick={() => handleImageClick(image.id)}
      >
        <img
          src={`/${image.src}`}
          alt={`Producto ${index + 1}`}
          className="object-cover w-full h-full"
        />
      </div>
    ));
  };

  return (
    <div className="relative overflow-hidden w-full h-40 sm:h-48 md:h-56 lg:h-64">
      <div
        className={`flex transform transition-transform duration-500 ease-in-out`}
        style={{ transform: `translateX(-${currentIndex * (100 / images.length)}%)` }}
        ref={sliderRef}
      >
        {renderImages()}
      </div>
    </div>
  );
}

export default SliderImagenes;
