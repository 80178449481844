import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
  frase1: { 
      imgSrc: '/AilensComponents/Chica/Numeros.webp',
      x: { mobile: 110, tablet: 110, desktop: 110 },
      y: { mobile: 5, tablet: 5, desktop:  5 }, 
      imgWidth: { mobile: 550, tablet: 550, desktop: 550 },
      imgHeight: { mobile: 550, tablet: 550, desktop: 550 },
  },
    frase2: { 
        text: "La transición más suave", 
        style: "font-now-thin text-white",
        x: { mobile: 110, tablet: 110, desktop: 110 }, 
        y: { mobile: 67, tablet: 67, desktop: 67 },
        fontSize: { mobile: 50, tablet: 50, desktop: 50}
    },
    frase3: { 
        text: "entre la visión de cerca,", 
        style: "font-now-thin text-white",
        x: { mobile: 110, tablet: 110, desktop: 110 }, 
        y: { mobile: 73, tablet: 73, desktop: 73 }, 
        fontSize: { mobile: 50, tablet: 50, desktop: 50 }
    },
    frase4: { 
        text: "intermedia y de lejos", 
        style: "font-now-thin text-white",
        x: { mobile: 112.5, tablet: 112.5, desktop: 112.5 }, 
        y: { mobile: 79, tablet: 79, desktop: 79 }, 
        fontSize: { mobile: 50, tablet: 50, desktop: 50 }
    }
};


function Chica() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/AiLensComponents/Chica/Senora.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Chica;
