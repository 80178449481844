import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {

};


export const QuintoProgresivos = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Progresivos/FondoBlancoLogo.webp"
        isBackground={true}
      />
    </div>
  );
}
