import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    
    frase1: { 
        imgSrc: '/Progresivos/Primero/Lentes.webp',
        x: { mobile: 105, tablet: 105, desktop: 105},
        y: { mobile: 30, tablet: 30, desktop:  30 }, 
        imgWidth: { mobile: 700, tablet: 700, desktop: 700 },
        imgHeight: { mobile: 550, tablet: 550, desktop: 550 },
    },
    frase2: { 
        text: "Somos expertos en",
        style: "font-now-thin text-white ",
        x: { mobile: 105, tablet: 105, desktop: 105}, 
        y: { mobile:  17 , tablet:  17 , desktop: 17 },
        fontSize: { mobile: 95, tablet: 95, desktop: 95}
    },
    frase3: { 
        text: "lentes progresivos",
        style: "font-now-bold text-white ",
        x: { mobile: 106.5, tablet: 106.5, desktop: 106.5}, 
        y: { mobile:  25 , tablet:  25 , desktop: 25 },
        fontSize: { mobile: 95, tablet: 95, desktop: 95}
    },
    frase4: { 
      text: "LEJOS",
      style: "font-now-bold text-white ",
      x: { mobile: 106.5, tablet: 106.5, desktop: 119}, 
      y: { mobile:  25 , tablet:  25 , desktop: 43 },
      fontSize: { mobile: 95, tablet: 95, desktop: 35}
  },
  frase5: { 
    text: "INTERMEDIA",
    style: "font-now-bold text-white ",
    x: { mobile: 106.5, tablet: 106.5, desktop: 111}, 
    y: { mobile:  25 , tablet:  25 , desktop: 57 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35}
  },
  frase6: { 
    text: "CERCA",
    style: "font-now-bold text-white ",
    x: { mobile: 106.5, tablet: 106.5, desktop: 125}, 
    y: { mobile:  25 , tablet:  25 , desktop: 75 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35}
  },
  frase7: { 
    text: <a> OBTÉN UNA <b>VISIÓN CÓMODA</b></a>,
    style: "font-now-thin text-white ",
    x: { mobile: 106.5, tablet: 106.5, desktop: 113}, 
    y: { mobile:  25 , tablet:  25 , desktop: 90 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35}
  },
  frase8: { 
    text: "EN TODAS LAS DISTANCIAS",
    style: "font-now-thin text-white ",
    x: { mobile: 106.5, tablet: 106.5, desktop: 114.5}, 
    y: { mobile:  25 , tablet:  25 , desktop: 95 },
    fontSize: { mobile: 95, tablet: 95, desktop: 35}
  },
};


export const PrimeroProgresivos = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Progresivos/Primero/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}
