import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
  frase1: { 
    text: "Somos expertos en",
    style: "font-now-thin text-white ",
    x: { mobile: 55, tablet: 55, desktop: 55}, 
    y: { mobile:  8 , tablet:  8 , desktop: 8 },
    fontSize: { mobile: 75, tablet: 75, desktop: 75}
  },
  frase2: { 
    text: "lentes progresivos",
    style: "font-now-bold text-white ",
    x: { mobile: 105, tablet: 105, desktop: 55}, 
    y: { mobile:  17 , tablet:  17 , desktop: 16 },
    fontSize: { mobile: 95, tablet: 95, desktop: 80}
  },
  frase3: { 
    text: "MONOFOCAL",
    style: "font-now-bold text-white ",
    x: { mobile: 105, tablet: 105, desktop: 58}, 
    y: { mobile:  17 , tablet:  17 , desktop: 29 },
    fontSize: { mobile: 95, tablet: 95, desktop: 60}
  },
  frase4: { 
    text: "BIFOCAL",
    style: "font-now-bold text-white ",
    x: { mobile: 105, tablet: 105, desktop: 93}, 
    y: { mobile:  17 , tablet:  17 , desktop: 29 },
    fontSize: { mobile: 95, tablet: 95, desktop: 60}
  },
  frase5: { 
    text: "MULTIFOCAL",
    style: "font-now-bold text-white ",
    x: { mobile: 105, tablet: 105, desktop: 123}, 
    y: { mobile:  17 , tablet:  17 , desktop: 29 },
    fontSize: { mobile: 95, tablet: 95, desktop: 60}
  },
  frase6: { 
    imgSrc: '/Progresivos/Segundo/LenteCero.webp',
    x: { mobile: 105, tablet: 105, desktop: 52},
    y: { mobile: 30, tablet: 30, desktop:  39 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 570 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 410 },
  },
  frase7: { 
    imgSrc: '/Progresivos/Segundo/LenteUno.webp',
    x: { mobile: 105, tablet: 105, desktop: 85},
    y: { mobile: 30, tablet: 30, desktop:  38 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 590 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 440 },
  },
  frase8: { 
    imgSrc: '/Progresivos/Segundo/LenteDos.webp',
    x: { mobile: 105, tablet: 105, desktop: 117},
    y: { mobile: 30, tablet: 30, desktop:  38 }, 
    imgWidth: { mobile: 700, tablet: 700, desktop: 570 },
    imgHeight: { mobile: 550, tablet: 550, desktop: 440 },
  },
    
};


export const SegundoProgresivos = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/Progresivos/Segundo/Fondo.webp"
        isBackground={true}
      />
    </div>
  );
}
