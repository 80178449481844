import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        imgSrc: '/AilensComponents/PerformancePorce/novesei.webp',
        x: { mobile: 66, tablet: 66, desktop: 66 },
        y: { mobile: 32, tablet: 32, desktop: 32 }, 
        imgWidth: { mobile: 350, tablet: 350, desktop: 350 },
        imgHeight: { mobile: 280, tablet: 280, desktop: 280 },
    },
    frase2: { 
        imgSrc: '/AilensComponents/PerformancePorce/noveidos.webp',
        x: { mobile: 84, tablet: 84, desktop: 84 },
        y: { mobile: 32, tablet: 32, desktop: 32 }, 
        imgWidth: { mobile: 350, tablet: 350, desktop: 350 },
        imgHeight: { mobile: 280, tablet: 280, desktop: 280 },
    },
    frase3: { 
        imgSrc: '/AilensComponents/PerformancePorce/ocheido.webp',
        x: { mobile: 101, tablet: 101, desktop: 101 },
        y: { mobile: 32, tablet: 32, desktop: 32 }, 
        imgWidth: { mobile: 350, tablet: 350, desktop: 350 },
        imgHeight: { mobile: 280, tablet: 280, desktop: 280 },
    },
    frase4: { 
        imgSrc: '/AilensComponents/PerformancePorce/ocheocho.webp',
        x: { mobile: 118, tablet: 118, desktop: 118 },
        y: { mobile: 32, tablet: 32, desktop: 32 }, 
        imgWidth: { mobile: 350, tablet: 350, desktop: 350 },
        imgHeight: { mobile: 280, tablet: 280, desktop: 280 },
    },
    frase5: { 
        text:  " Mejoraron su visión", 
        style: "font-now text-gray-800",
        x: { mobile: 68.5, tablet: 68.5, desktop: 68.5 }, 
        y: { mobile: 60, tablet: 60, desktop: 60 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase6: { 
        text:  "respecto a sus ", 
        style: "font-now text-gray-600",
        x: { mobile: 70, tablet: 70, desktop: 70 }, 
        y: { mobile: 64, tablet: 64, desktop: 64 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase7: { 
        text:  "anteriores multifocales", 
        style: "font-now text-gray-600",
        x: { mobile: 67.5, tablet: 67.5, desktop: 67.5 }, 
        y: { mobile: 68, tablet: 68, desktop: 68 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    }, 
    
    

    frase8: { 
        text:  "Informaron un mejor", 
        style: "font-now text-gray-800",
        x: { mobile: 85.5, tablet: 85.5, desktop: 85.5 }, 
        y: { mobile: 60, tablet: 60, desktop: 60 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase9: { 
        text:  "confort visual respecto", 
        style: "font-now text-gray-600",
        x: { mobile: 85, tablet:85, desktop: 85 }, 
        y: { mobile: 64, tablet: 64, desktop: 64 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase10: { 
        text:  "a sus anteriores lentes", 
        style: "font-now text-gray-600",
        x: { mobile: 85, tablet: 85, desktop: 85 }, 
        y: { mobile: 68, tablet: 68, desktop: 68 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    
 
 
    frase11: { 
        text:  "Mejoraron su", 
        style: "font-now text-gray-800",
        x: { mobile:  105.5, tablet:  105.5, desktop: 105.5 }, 
        y: { mobile: 60, tablet: 60, desktop: 60 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase12: { 
        text:  "experiencia de visión", 
        style: "font-now text-gray-600",
        x: { mobile: 103, tablet: 103, desktop: 103 }, 
        y: { mobile: 64, tablet: 64, desktop: 64 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase13: { 
        text:  "en el anochecer.", 
        style: "font-now text-gray-600",
        x: { mobile: 104.5, tablet: 104.5, desktop: 104.5 }, 
        y: { mobile: 68, tablet: 68, desktop: 68 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    
    
  
    frase14: { 
        text:  "Redujeron el tiempo", 
        style: "font-now text-gray-800",
        x: { mobile: 120, tablet: 120, desktop: 120 }, 
        y: { mobile: 60, tablet: 60, desktop: 60 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase15: { 
        text:  "de adaptación con los", 
        style: "font-now text-gray-600",
        x: { mobile: 119.5 , tablet: 119.5 , desktop: 119.5 }, 
        y: { mobile: 64, tablet: 64, desktop: 64 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
    frase16: { 
        text:  "  lentes.", 
        style: "font-now text-gray-600",
        x: { mobile: 124.5, tablet:124.5, desktop: 124.5 }, 
        y: { mobile:  68, tablet:  68, desktop: 68 }, 
        fontSize: { mobile: 27, tablet: 27, desktop: 27 }
    },
};

function PerformancePor() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/AiLensComponents/PerformancePorce/performanceNumeros.webp"
        isBackground={true}
      />
    </div>
  );
}

export default PerformancePor;