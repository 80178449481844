import React from 'react';
//Animacion
import AnimatedSection from '../shared/hooks/useAnimation';

//ParteInicial
import Chica from './pages/Chica.blueClarity';
import Gris from './pages/Gris.blueClarity';
import Azul from './pages/Azul.blueClarity';
import ChicaTriste from './pages/ChicaTriste.blueClarity';
import Ojo from './pages/Ojo.blueClarity';
import LentesUno from './pages/LentesUno.blueClarity';
import LentesDos from './pages/LentesDos.blueClarity';
import LentesTres from './pages/LentesTres.blueClarity';
import ChicaEs from './pages/ChicaEs.blueClarity';
import Sucursales from '../Inicio/pages/Sucursales.inicio';


function BlueClarity() {
    return (
        <div className="flex flex-col" style={{ overflowY: 'hidden', overflowX: 'auto', margin: 0, padding: 0 }}>
            <AnimatedSection>
                <div className="flex justify-center items-center h-screen">
                    <div className="w-1/2 md:w-1/5 lg:w-1/6"> 
                        <img
                            src="/BlueClarity/Logo.webp"
                            alt="Logo Stop Age"
                            className="w-full h-auto object-contain" 
                        />
                    </div>
                </div>
            </AnimatedSection>
            {[Chica, Gris, Azul, ChicaTriste, Ojo, LentesUno, LentesDos, LentesTres, ChicaEs, Sucursales].map((Component, index) => (
                <AnimatedSection key={index}>
                    <div className="relative flex items-center justify-center " style={{ margin: 0, padding: 0 }}>
                        
                        <Component />
                    </div>
                </AnimatedSection>
            ))}
        </div>
    );
}
export default BlueClarity;
