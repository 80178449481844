import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function AnimatedSection({ children, className }) {
    const { ref, inView } = useInView({
        threshold: 0.3,
    });

    const controls = useAnimation();

    React.useEffect(() => {
        controls.start({
            opacity: inView ? 1 : 0,
            y: inView ? 0 : 50, // Aumenta el valor de y para que se deslice más
        });
    }, [inView, controls]);

    return (
        <motion.div
            ref={ref}
            animate={controls}
            initial={{ opacity: 0, y: 50 }} 
            transition={{ duration: 0.8, ease: 'easeOut' }}
            className={`relative ${className}`} 
        >
            {children}
        </motion.div>
    );
}

export default AnimatedSection;
