import React from 'react';
import { IoLogoFacebook } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import { FaShieldAlt } from "react-icons/fa";

function Footer() {
    return (
        <div className="bg-gray-600 text-white py-5 z-10">
            <div className="flex flex-col md:flex-row justify-between items-center mb-4 px-5">
                <div className="flex items-center mb-4 md:mb-0">
                    <p className="mr-3 text-sm">SÍGUENOS:</p>
                    <div className="flex gap-4 text-xl">
                        <IoLogoFacebook onClick={() => window.open("https://www.facebook.com/OpticentroBolivia", "_blank")} className="cursor-pointer hover:text-blue-500 transition-colors duration-300" />
                        <FaInstagram onClick={() => window.open("https://www.instagram.com/opticentro_bolivia/", "_blank")} className="cursor-pointer hover:text-pink-500 transition-colors duration-300" />
                        <FaTiktok onClick={() => window.open("https://www.tiktok.com/@opticentrobolivia", "_blank")} className="cursor-pointer hover:text-black transition-colors duration-300" />
                    </div>
                </div>
                <p className="text-xs text-center mb-4 md:mb-0">© OPTICENTRO 2024</p>
                <div className="flex items-center">
                    <FaShieldAlt className="text-2xl mr-3" />
                    <div className="text-xs leading-none">
                        <p>PAGO SEGURO</p>
                        <p>GARANTIZADO</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
