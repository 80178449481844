import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        imgSrc: '/AilensComponents/Disponibilidad/un.webp',
        x: { mobile: 63, tablet: 63, desktop: 63 },
        y: { mobile: 48, tablet: 48, desktop: 48 }, 
        imgWidth: { mobile: 325, tablet: 325, desktop: 325 },
        imgHeight: { mobile: 100, tablet: 100, desktop: 100 },
    },
    frase2: { 
        imgSrc: '/AilensComponents/Disponibilidad/do.webp',
        x: { mobile: 122, tablet: 122, desktop: 122 },
        y: { mobile: 48, tablet: 48, desktop: 48 }, 
        imgWidth: { mobile: 275, tablet: 275, desktop: 275 },
        imgHeight: { mobile: 90, tablet: 90, desktop: 90 },
    },
    frase3: { 
        imgSrc: '/AilensComponents/Disponibilidad/tre.webp',
        x: { mobile: 102, tablet: 102, desktop: 102 },
        y: { mobile: 48, tablet: 48, desktop: 48}, 
        imgWidth: { mobile: 225, tablet: 225, desktop: 225 },
        imgHeight: { mobile: 85, tablet: 85, desktop: 85 },
    },
    frase4: { 
        imgSrc: '/AilensComponents/Disponibilidad/cuat.webp',
        x: { mobile: 87, tablet: 87, desktop: 87 },
        y: { mobile: 48, tablet: 48, desktop: 48 }, 
        imgWidth: { mobile: 155, tablet: 155, desktop: 155 },
        imgHeight: { mobile: 110, tablet: 110, desktop: 110 },
    },  
    frase5: { 
        text: "DISPONIBLE CON TODOS LOS TRATAMIENTOS",
        style: "font-now-thin text-gray-600",
        x: { mobile: 73, tablet: 73, desktop: 73 }, 
        y: { mobile: 25, tablet: 25, desktop: 25 },
        fontSize: { mobile: 55, tablet: 55, desktop:55}
    }
};

function Disponibilidad() {
    return (
        <div style={{ width: '100%', height: '100%' }}>
        <AnimatedSection
            frases={frases}
            imageSrc="/AiLensComponents/Fondo.webp"
            isBackground={true}
        />
        </div>
    );
}

export default Disponibilidad;