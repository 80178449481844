import React from "react";

const Banner = () => {
    return (
        <div className="w-full overflow-hidden">
            <img
                src="/Banner/Banner.webp"
                className="w-full h-auto object-cover"
                alt="Banner de lentes"
            />
        </div>
    );
};

export default Banner;
