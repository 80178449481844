import React from 'react';
//Animacion
import AnimatedSection from '../shared/hooks/useAnimation';

//Components
import Chica from './pages/Chica.stopAge.page';
import Chica2 from './pages/Chica2stopAge.page';
import Provocando from './pages/Provocando.stopAge.page';
import Sol from './pages/Sol.stopAge.page';
import LuzInfrarroja from './pages/LuzInfraroja.stopAge.page';
import Gris from './pages/Gris.stopAge,page'; 
import Estufa from './pages/Estufa.stopAge.page';
import Piel from './pages/Piel.stopAge.page';
import LenteLaser from './pages/LentesLaser.stopAge.page';
import YouTubeVideo from './components/VideoYutu.stopAge.components';
import Lentes from './pages/Lentes.stopAge.page'; 
import Sucursales from '../Inicio/pages/Sucursales.inicio';

function StopAge() {
    return (
        <div className="flex flex-col" style={{ overflowY: 'hidden', overflowX: 'auto', margin: 0, padding: 0 }}>
            <AnimatedSection>
                <div className="flex justify-center items-center h-screen">
                    <div className="w-1/4 md:w-1/5 lg:w-1/6"> 
                        <img
                            src="/StopAge/StopAgeLogo/StopAgeLogo.webp"
                            alt="Logo Stop Age"
                            className="w-full h-auto object-contain" 
                        />
                    </div>
                </div>
            </AnimatedSection>
            {[Chica, Chica2, Provocando, Sol, LuzInfrarroja, Gris, Estufa, Piel, LenteLaser,Lentes ,YouTubeVideo, Sucursales].map((Component, index) => (
                <AnimatedSection key={index}>
                    <div className="relative flex items-center justify-center " style={{ margin: 0, padding: 0 }}>
                        {index !== 2 && index !== 6 && index !==10 && index !== 11 && <GlobalImage />}
                        <Component />
                    </div>
                </AnimatedSection>
            ))}
        </div>
    );
}

function GlobalImage() {
    return (
        <div className="absolute top-0 left-[5%] z-10 w-1/6 md:w-1/7 lg:w-1/8">
            <img
                src="/StopAge/Global.webp"
                alt="Global Image"
                className="w-full h-auto object-contain"
            />
        </div>
    );
}

export default StopAge;
