import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared'

const frases = {
    frase1: { 
        text: "CONÓCENOS",
        style: "font-now-bold",
        x: { mobile: 89, tablet: 89, desktop: 89 }, 
        y: { mobile: 5, tablet: 5, desktop: 5}, 
        fontSize: { mobile: 110, tablet: 90, desktop: 70 }
    },
    frase2: {
        ytSrc: "https://www.youtube.com/embed/fInABa62mno?autoplay=1&mute=1&controls=0&showinfo=0&autohide=1", 
        x: { mobile: 67, tablet: 67, desktop: 67 }, 
        y: { mobile: 15, tablet: 15, desktop: 15 },
        imgWidth: { mobile: 1280, tablet:1280, desktop: 1280},  
        imgHeight: { mobile: 720, tablet: 720, desktop: 720},
    }
};

function Conocenos() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        isBackground={true}
      />
    </div>
  );
}

export default Conocenos;
