import React from 'react';

const Construccion = () => {
  return (
    <div className=" flex items-center justify-center">
      <div className="w-[50%] h-[50%] overflow-hidden flex items-center justify-center">
        <img 
          src="https://th.bing.com/th/id/OIP.1Qp0KmoBNxp-qSuuo2AbtAHaGq?rs=1&pid=ImgDetMain" 
          alt="Pantramed" 
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default Construccion;
