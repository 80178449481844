import React from 'react';
import AnimatedSection from '../../shared/components/Dynamic.shared';

const frases = {
    frase1: { 
        text: <strong>PROTECCIÓN TOTAL</strong>,
        style: "font-now-thin text-white",
        x: { mobile: 83, tablet: 83, desktop: 83 }, 
        y: { mobile: 6, tablet: 6, desktop: 6}, 
        fontSize: { mobile: 75, tablet: 75, desktop: 75 }
    },
    frase2: { 
        text:  <strong>STOP AGE</strong>,
        style: "font-now text-white",
        x: { mobile: 106, tablet: 106, desktop: 106 }, 
        y: { mobile: 56, tablet: 56, desktop: 56 }, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60 }
    },
    frase3: { 
        text: ", un lente capaz de", 
        style: "font-now-thin text-white",
        x: { mobile: 120, tablet: 120, desktop: 120 }, 
        y: { mobile: 56, tablet: 56, desktop: 56 }, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60 }
    },
    frase4: { 
        text: "controlar la luz infrarroja, que", 
        style: "font-now-thin text-white",
        x: { mobile: 107, tablet: 107, desktop: 107 }, 
        y: { mobile: 62, tablet: 62, desktop: 62 }, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60 }
    },
    frase5: { 
        text:  "nos protege también de la luz", 
        style: "font-now-thin text-white",
        x: { mobile: 107, tablet: 107, desktop: 107 }, 
        y: { mobile: 68, tablet: 68, desktop: 68 }, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60 }
    },
    frase6: { 
        text: "ultravioleta y la luz azul. ", 
        style: "font-now-thin text-white",
        x: { mobile: 115, tablet: 115, desktop: 115 }, 
        y: { mobile: 74, tablet: 74, desktop: 74 }, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60 }
    },
    frase7: { 
        text: "Ayudando a prevenir el", 
        style: "font-now-thin text-white",
        x: { mobile: 115, tablet: 115, desktop: 115 }, 
        y: { mobile: 80, tablet: 80, desktop: 80 }, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60 }
    },
    frase8: { 
        text:  "envejecimiento prematuro de", 
        style: "font-now-thin text-white",
        x: { mobile: 108, tablet: 108, desktop: 108 }, 
        y: { mobile: 86, tablet: 86, desktop: 86 }, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60 }
    },
    frase9: { 
        text:  "tus ojos y piel.", 
        style: "font-now-thin text-white",
        x: { mobile: 128, tablet: 128, desktop: 128 }, 
        y: { mobile: 92, tablet: 92, desktop: 92 }, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60 }
    },
    frase10: { 
        imgSrc: '/StopAge/Lent/Lentes1.webp', 
        x: { mobile: 58, tablet: 58, desktop: 58 }, 
        y: { mobile: 25, tablet: 25, desktop: 25 }, 
        imgWidth: { mobile: 700, tablet: 700, desktop: 700 },
        imgHeight: { mobile: 660, tablet: 660, desktop: 660 },
    },
    frase11: { 
        imgSrc: '/StopAge/Lent/Lentes4.webp',
        x: { mobile: 74, tablet: 74, desktop: 74}, 
        y: { mobile: 34, tablet: 34, desktop: 34 }, 
        imgWidth: { mobile: 500, tablet: 500, desktop: 500 },
        imgHeight: { mobile: 460, tablet: 460, desktop: 460 },
    },
    frase12: { 
        imgSrc: '/StopAge/Lent/Lentes3.webp',
        x: { mobile: 77.5, tablet: 77.5, desktop: 77.5 }, 
        y: { mobile: 23, tablet: 23, desktop: 23 }, 
        imgWidth: { mobile: 430, tablet: 430, desktop: 430 },
        imgHeight: { mobile: 380, tablet: 380, desktop: 380 },
    },
    frase13: { 
        imgSrc: '/StopAge/Lent/Lentes2.webp',
        x: { mobile: 83, tablet: 83, desktop: 83 }, 
        y: { mobile: 12, tablet: 12, desktop: 12 }, 
        imgWidth: { mobile: 320, tablet: 320, desktop: 320 },
        imgHeight: { mobile: 270, tablet: 270, desktop: 270 },
    },
    frase14: { 
        text: "INFRARROJO", 
        style: "font-now-thin text-white",
        x: { mobile: 100, tablet: 100, desktop: 100}, 
        y: { mobile: 15.5, tablet: 15.5, desktop: 15.5 }, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60 }
    },
    frase15: { 
        text:  "ULTRAVIOLETA", 
        style: "font-now-thin text-white",
        x: { mobile: 100, tablet: 100, desktop: 100 }, 
        y: { mobile: 26.5, tablet: 26.5, desktop: 26.5 }, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60 }
    },
    frase16: { 
        text:  "LUZ AZUL", 
        style: "font-now-thin text-white",
        x: { mobile: 100, tablet: 100, desktop: 100 }, 
        y: { mobile: 36.5, tablet: 36.5, desktop: 36.5 }, 
        fontSize: { mobile: 60, tablet: 60, desktop: 60 }
    },
};

function Lentes() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/StopAge/Lent/Lentes.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Lentes;