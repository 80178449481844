import React from 'react';
import './Mensaje.css';

const Mensaje = () => {
    const mensaje = "Opticentro tiene una solución para garantizar tu comodidad visual. Para tus actividades deportivas, una ocasión especial o para el día a día, nuestros lentes de contacto cumplen con tus expectativas.";
    return (
        <p className="texto-apoyo text-gray-500">
            {mensaje}
        </p>
    );
};

export default Mensaje;