import React from 'react';

function Transitions() {
  return (
    <div>
      <h1>TRANSITIONS</h1>
    </div>
  );
}

export default Transitions;